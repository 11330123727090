.form-group{
  @apply flex flex-col;
}

.form-input-group {

  label {
    @apply mb-3 text-xl;
  }

  label::after {
    @apply text-primary;
    content: '*';
  }
}

input {
  @apply h-[60px] text-lg #{!important};
}

nz-select {
  @apply h-[60px] text-lg #{!important};
  nz-select-top-control {
    @apply h-[60px] text-lg #{!important};
    nz-select-item, nz-select-search {
      @apply h-[60px] flex items-center text-lg #{!important};
    }
  }
}

.error {
  @apply hidden text-sm text-red-500;
}
.form-input-group.show-errors {
  .error {
    @apply block mt-1;
  }
}
/*

label {
  @apply mb-2;
}
*/
