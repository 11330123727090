/* You can add global styles to this file, and also import other style files */

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "assets/scss/global.scss";

h2 {
  @apply text-2xl text-dark-text font-semibold;
}

p, .paragraph {
  @apply text-base text-light-text;

  span {
    @apply font-semibold text-dark-text;
  }
}

label {
  @apply font-semibold text-xl text-dark-text block;
}

.ant-result-subtitle {
  @apply text-lg;
}

html, body {
  @apply overflow-x-hidden;
}

@screen md {
  h2 {
    @apply text-3xl;
  }
  p, .paragraph {
    @apply text-xl text-light-text;

    span {
      @apply font-semibold text-dark-text;
    }
  }
}
