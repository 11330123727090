.steps-content {
  @apply w-full mr-auto mt-0 ml-0;
}

:host {
  @apply w-full h-full flex justify-center;
}
.auth {
  @apply bg-white p-0 flex flex-col h-full justify-start items-center gap-5 w-full overflow-y-auto;
}

.main-section {
  @apply w-full flex-1 h-max md:h-full relative rounded-tl-[31px] rounded-tr-[31px] md:rounded-none -top-12 bg-white;
}
nz-carousel .ant-carousel .slick-dots {
  @apply flex justify-start mr-0 #{!important};
}

@screen md {
  .auth {
    @apply flex items-center h-full flex-row gap-0; /*p-5*/
  }
  .main-section {
    top: 0;
    width: calc(100% - 300px);
  }
  .steps-content {
    @apply pt-0;
  }
}

@screen lg {
  .auth {
    @apply flex-row gap-0;
  }
  .main-section {
    width: calc(100% - 400px);
  }
  .steps-content {
    @apply w-full mt-0 ml-0 pb-0;
  }
}
